import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import BusinessCard from '../middle/BusinessCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    businessCardsBox: {
      padding: `1rem 0`,
      marginTop: `-2rem`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 20%, ${theme.palette.background.default} 50%, ${theme.palette.background.default} 100%)`,
    },
    cardsWrapper: {
      maxWidth: `1200px`,
      margin: `2rem auto`,
      width: `90%`,
    },
  }),
);

const BusinessCardBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.businessCardsBox}>
      <Grid container className={classes.cardsWrapper} spacing={3}>
        <Grid item xs={12} md={4}>
          <BusinessCard
            cardTitle="個人の皆さま"
            contentsList={[
              `税務相談の受付`,
              `確定申告のお手伝い`,
              `住宅ローンに関する特別控除のご相談`,
              `医療費に関する特別控除のご相談`,
              `贈与税、相続税対策のお手伝い`,
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BusinessCard
            cardTitle="個人事業主の皆さま"
            contentsList={[
              `月次の記帳代行のお手伝い`,
              `年末調整、決算業務のお手伝い`,
              `各種届出書、税務書類作成のお手伝い`,
              `定期的な巡回`,
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BusinessCard
            cardTitle="法人の皆さま"
            contentsList={[
              `会社設立のお手伝い`,
              `年末調整、決算業務のお手伝い`,
              `各種届出書、税務書類作成のお手伝い`,
              `定期的な巡回`,
            ]}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default BusinessCardBox;
