import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import BusinessCardBox from '@/components/business/section/BusinessCardsBox';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';

const Business: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="業務内容"
      descriptionData="私たちは個人・法人問わず、多くの皆さまをお手伝いしています。"
      OgImageData="Business"
    />
    <FirstView pageTitle="業務内容" fileName="Business" />
    <BusinessCardBox />
    <BottomMenu />
  </Layout>
);

export default Business;
