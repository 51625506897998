import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { orange } from '@material-ui/core/colors';

import FirstViewImage from '../basic/FirstViewImage';

type FirstViewProps = {
  pageTitle: string;
  fileName:
    | 'Access'
    | 'Business'
    | 'Contact'
    | 'ExLink'
    | 'Greeting'
    | 'News'
    | 'OfficeInfo'
    | 'Questions'
    | 'Town'
    | '404';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    FirstViewStyle: {
      backgroundColor: orange[500],
      padding: `64px 0 0`,
      [theme.breakpoints.up(`lg`)]: {
        paddingBottom: `1rem`,
      },
    },
    FirstViewTitleBox: {
      width: `90%`,
      margin: `1rem auto`,
      [theme.breakpoints.up(`lg`)]: {
        width: `auto`,
        margin: `auto`,
        marginLeft: `max(10vw, calc((100vw - 1280px)/2))`,
      },
    },
    brAdjust: {
      display: `inline-block`,
    },
  }),
);

const FirstView: React.VFC<FirstViewProps> = ({ pageTitle, fileName }) => {
  const classes = useStyles();

  return (
    <section className={classes.FirstViewStyle}>
      <Grid container alignItems="center">
        <Grid xs={12} lg={7}>
          <FirstViewImage fileName={fileName} />
        </Grid>
        <Grid xs={12} lg={5}>
          <div className={classes.FirstViewTitleBox}>
            <Typography variant="h1" align="left">
              {pageTitle}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default FirstView;
