import React from 'react';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

type BusinessCardProps = {
  cardTitle: string;
  contentsList: Array<string>;
};

const useStyles = makeStyles({
  businessPaperTitle: {
    margin: `.5rem auto`,
    padding: `0 16px`,
  },
  businessPaper: {
    padding: `calc(.5rem + 1vw)`,
  },
  itemText: {
    wordBreak: `keep-all`,
  },
});

const BusinessCard: React.VFC<BusinessCardProps> = ({
  cardTitle,
  contentsList,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.businessPaper}>
      <Typography
        variant="h3"
        component="h2"
        className={classes.businessPaperTitle}
      >
        {cardTitle}
      </Typography>
      <List>
        {contentsList.map((content) => (
          <ListItem dense>
            <ListItemText secondary={content} className={classes.itemText} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default BusinessCard;
